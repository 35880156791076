"use strict";

var player = function() {
    const player = new Plyr('#player');
    
    player.source = {
        type: 'video',
        title: 'Some Title Video',
        sources: [
            {
                src: './media/sample-720.mp4',
                type: 'video/mp4',
                size: 720
            }
        ],
        // poster: './img/video-placeholder.png'
        // sources: [
        //     {
        //         src: '143418951',
        //         provider: 'vimeo'
        //     },
        // ],
    };
}

$(function() {

    player();

});