"use strict";

var animations = function () {
    // set scene controller
    var controller = new ScrollMagic.Controller();
    var slides = $('.fullscreen');
    var timeline = new TimelineMax();
    var tween1 = TweenMax.to('.radius-tween', .5, {
        borderTopLeftRadius: '0', borderTopRightRadius: '0'
    });
    var tween2 = TweenMax.to('.menu-tween', 1, {
        opacity: '1', translateX: '0', backfaceVisibility: 'visible',
    });

    if ($(window).width() <= 992) {
        for (var i = 0; i < slides.length; i++) {
            new ScrollMagic.Scene({
                triggerElement: slides[i],
                triggerHook: 'onLeave'
            })
                .setPin(slides[i], { pushFollowers: false })
                .addTo(controller);
        }
    }

    // Mobile Section Radius
    if ($('#nieinwazyjne-badanie-prenatalne-nipt').length > 0) {
        var tweenScene = new ScrollMagic.Scene({
            triggerElement: '#nieinwazyjne-badanie-prenatalne-nipt',
            triggerHook: .5,
            duration: 300
        });
        timeline.add(tween1).add(tween2);
        tweenScene.setTween(timeline)
        tweenScene.addTo(controller);
    }

    if ($('#float-sidebar').length > 0) {
        new ScrollMagic.Scene({
            triggerElement: '#float-sidebar',
            triggerHook: 'onLeave'
        })
            .setPin('#float-sidebar', { pushFollowers: false })
            .addTo(controller);
    }

    var pinnedMenu = new ScrollMagic.Scene({
        triggerElement: '#tweenMenu',
        triggerHook: 'onLeave'
    })
        .setPin('#tweenMenu')
        .addTo(controller);

    if ($('.modal-box').length > 0) {
        for (var i = 0; i < $('.modal-box').length; i++) {
            var modalBox = new ScrollMagic.Scene({
                triggerElement: $('.modal-box')[i],
                triggerHook: 0,
            })
                .setPin($('.modal-box')[i], { pushFollowers: false })
                .addTo(controller);
        }
    }
};

var responsiveScrollMagic = function () {

    var tooSmall = false;
    var controller = null;
    var maxWidth = 992;

    if ($(window).width() < maxWidth) {
        tooSmall = true;
    }

    function initScrollMagic() {
        controller = new ScrollMagic.Controller();

        if ($('.main-view').length > 0) {
            new ScrollMagic.Scene({
                triggerElement: '.main-view',
                triggerHook: 0,
            })
                .setClassToggle('.main-view', 'animate')
                .addTo(controller);
        }

        new ScrollMagic.Scene({
            triggerElement: '.page-view-container',
            triggerHook: .9
        })
            .setClassToggle('.page-view-container', 'animate')
            .addTo(controller);

        var banner = new ScrollMagic.Scene({
            triggerElement: '.split-banner-wrapper',
            triggerHook: 'onLeave'
        })
            .setPin('.split-banner-wrapper', { pushFollowers: false })
            .addTo(controller);

        var menu = new ScrollMagic.Scene({
            triggerElement: '#menu-box',
            triggerHook: 0
        })
            .setPin('#menu-box', { pushFollowers: false })
            .addTo(controller);
    }

    if (!tooSmall) {
        initScrollMagic();
    }

    // zmiana szerokości okna przeglądarki może wielokrotnie odpalać skrypt
    // to rozwiązanie pozwala wstrzymać controller przed wielokrotnym wznawianiem lub zniszczeniem
    $(window).resize(function () {
        var wWidth = $(window).width();
        if (wWidth < maxWidth) {
            if (controller !== null && controller !== undefined) {
                // niszczy controller
                controller = controller.destroy(true);
            }
        } else if (wWidth >= maxWidth) {
            if (controller === null || controller === undefined) {
                // wznawia skrypt / controller jeśli nie został on wznowiony wcześniej
                initScrollMagic();
            }
        }
    });
};

var textOrphans = function () {
    $('p').each(function () {

        if ($(this).html().match(/class="order"/g)) return;

        var tekst = $(this).html();
        tekst = tekst.replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;"); //jednoznakowe
        tekst = tekst.replace(/(\s)([^<][\S]{1})[\s]+/g, "$1$2&nbsp;"); //dwuznakowe
        $(this).html(tekst);
    });
};

var cookies = function () {
    if ($.cookie('giodoCookies') !== '1') {
        $('.cookies-alert').css('display', 'block');
    }
    $('.cookies-alert button').on('click', function () {
        $('.cookies-alert').fadeOut(300, function () {
            $.cookie('giodoCookies', '1', { expires: 365 });
        });
    });
    $('.cookies-alert-header img').on('click', function () {
        $('.cookies-alert').fadeOut(300);
    });
};

var articleSidebar = function (elPos, elHeight, targetLi) {
    targetLi.each(function () {
        var $this = $(this);
        if ($this.offset().top > elPos && $this.offset().top < (elPos + elHeight)) {
            TweenMax.to($this, .3, { background: '#fff' });
        } else {
            if ($this.hasClass('dash-active')) {
                TweenMax.to($this, .3, { background: '#8daa4d' });
            } else {
                TweenMax.to($this, .3, { background: '#445b66' });
            }
        }
    });
};

var scrollSection = function () {
    if ($(window).width() > 992) {
        $.scrollify({
            section: ".section-scroll",
            sectionName: "section-name",
            updateHash: true,
            setHeights: false,
            touchScroll: false,
            offset: 0,
            scrollSpeed: 800,
            scrollbars: true,
            afterResize() {
                if (window.innerWidth <= 992) {
                    $.scrollify.disable();
                } else {
                    $.scrollify.enable();
                }
                $.scrollify.update();
            },
        });
    }
}

function openModal(trigger) {
    $('body').addClass('disable-scroll');
    $('.modal').removeClass('modal-active');
    $('.modal[data-target-value=' + trigger + ']').toggleClass('modal-active');
    $.scrollify.disable();
}

function closeModal(trigger) {
    $('body').removeClass('disable-scroll');
    $('.modal[data-target-value=' + trigger + ']').removeClass('modal-active');
    $.scrollify.enable();
}

function resize() {
    var vheight = $(window).height();
    var vwidth = $(window).width();
    $('.fullscreen').css({
        'height': vheight,
        'width': vwidth
    });
}

$(document).on('click', '#polityka-prywatnosci', function (e) {
    e.preventDefault();
    $.scrollify.move('#prywatnosc');
})
$(document).on('click', '#polityka-cookies', function (e) {
    e.preventDefault();
    $.scrollify.move('#cookies');
})

$(document).on('click', 'a', function (e) {
    var $this = $(this);
    if ($this[0].hash.length > 0) {
        e.preventDefault();
        var $t = $this.attr('href');
        TweenLite.to(window, .8, {
            scrollTo: {
                y: $($t).offset().top
            },
            ease: Power3.easeOut
        });
    }
});

$(document).on('mouseenter mouseleave', '.navbar-wrapper', function () {
    $(this).parent('div').toggleClass('navbar-active');
    if ($('.navbar').hasClass('navbar-active')) {
        $('.float-sidebar').css('width', '100%');
    } else {
        $('.float-sidebar').removeAttr('style');
    }
});

$(document).on('click', '.article-list li', function () {
    var articleSelectID = $(this).data('article-select');
    TweenLite.to(window, .5, {
        scrollTo: {
            y: $('article[data-article="' + articleSelectID + '"]').offset().top
        },
        ease: Power3.easeOut
    });
});

$(window).on('scroll load', function () {
    var article = $('article');
    var $windowTop = $(window).scrollTop();

    article.each(function () {
        var id = $(this).data('article');
        var $current = 'current';
        var $dashCurrent = 'dash-active';

        if ($windowTop > ($(this).offset().top - $(this).height() / 2)) {
            $('.article-list li').removeClass($current);
            $('.article-list li .dash').removeClass($dashCurrent);
            $('.article-list li[data-article-select="' + id + '"]').addClass($current);
            $('.article-list li[data-article-select="' + id + '"] .dash').addClass($dashCurrent);
        }
    })


    if ($('article.green').length > 0 && $(window).width() > 992) {
        var $article = $('article.green'),
            $logo = $('#brand-logo'),
            green_pos = $article.offset().top,
            green_height = $article.height(),
            brand_logo_pos = $logo.offset().top - 14,
            targetMenu = $('.article-list ul li .dash');

        if (brand_logo_pos > green_pos && brand_logo_pos < (green_pos + green_height)) {
            TweenMax.to($logo, .2, { fill: '#fff' });
        } else {
            TweenMax.to($logo, .2, { fill: '#000' });
        }

        articleSidebar(green_pos, green_height, targetMenu);
    }
});

$(window).on('resize', function () {
    resize();
    scrollSection();
});

// $('.has-tooltip img').on('mousemove', function (event) {
//     var that = $(this);
//     if(window.innerWidth >= 992) {
//         var $zoom = $('#zoom-tooltip');
//         $zoom.css({
//             'top': ((event.clientY - window.innerHeight) + that.height() / 2),
//             'left': ((event.clientX - window.innerWidth) + that.width() / 2.5),
//             // 'top': ((event.clientY - window.innerHeight) + $(this).height() / 2) + 'px',
//             // 'left': ((event.clientX - window.innerWidth) + $(this).width() / 2.5) + 'px'
//         });
//     }
// })

function magnify(imgID, zoom) {
    var img, glass, w, h, bw;
    img = document.querySelector(imgID);
    /*create magnifier glass:*/
    glass = document.createElement("DIV");
    glass.setAttribute("class", "img-magnifier-glass");
    /*insert magnifier glass:*/
    img.parentElement.insertBefore(glass, img);
    /*set background properties for the magnifier glass:*/
    glass.style.backgroundImage = "url('" + img.src + "')";
    glass.style.backgroundRepeat = "no-repeat";
    glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    glass.addEventListener("mousemove", moveMagnifier);
    img.addEventListener("mousemove", moveMagnifier);
    /*and also for touch screens:*/
    glass.addEventListener("touchmove", moveMagnifier);
    img.addEventListener("touchmove", moveMagnifier);
    function moveMagnifier(e) {
        var pos, x, y;
        /*prevent any other actions that may occur when moving over the image*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        x = pos.x;
        y = pos.y;
        /*prevent the magnifier glass from being positioned outside the image:*/
        if (x > img.width - (w / zoom)) { x = img.width - (w / zoom); }
        if (x < w / zoom) { x = w / zoom; }
        if (y > img.height - (h / zoom)) { y = img.height - (h / zoom); }
        if (y < h / zoom) { y = h / zoom; }
        /*set the position of the magnifier glass:*/
        glass.style.left = (x - w) + "px";
        glass.style.top = (y - h) + "px";
        /*display what the magnifier glass "sees":*/
        glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
    }
    function getCursorPos(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /*get the x and y positions of the image:*/
        a = img.getBoundingClientRect();
        /*calculate the cursor's x and y coordinates, relative to the image:*/
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /*consider any page scrolling:*/
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
    }
}

$(function () {

    resize();
    animations();
    cookies();
    textOrphans();
    responsiveScrollMagic();
    scrollSection();

    if($('#diagram-magnify').length > 0) {
        magnify(".magnify-img", 2);
    }
});
