new Vue({
    data() {
        return {
            isActive: false,
            mobileView: false,
            desktopView: false,
            portal_name: 'Roche - Harmony'
        }
    },
    mounted() {
        window.addEventListener('resize', this.responsiveWidth);
        this.responsiveWidth();
    },
    methods: {
        isMenu() {
            var bodyElement = document.querySelector('body').classList;
            this.isActive = !this.isActive;
            if(this.isActive === true) {
                bodyElement.add('disable-scroll');
            } else {
                bodyElement.remove('disable-scroll');
            }
        },
        responsiveWidth() {
            var windowWidth = document.documentElement.clientWidth;
            if(windowWidth > 992) {
                this.desktopView = true;
                this.mobileView = false;
            } else {
                this.desktopView = false;
                this.mobileView = true;
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.responsiveWidth)
    }
}).$mount('#app')